import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import {
  selectComparisonBoardProducts,
  selectComparisonBoardProductTemplate,
} from '../../../state/comparisonBoard/selectors'
import HeaderRow from './HeaderRow'
import BodyRows from './BodyRows'

function Table({ template, productsData }) {
  return (
    <table
      className={classNames({
        'comparison-board-table': true,
        'comparison-board-table--large': productsData.length <= 5,
      })}
    >
      <thead>
        <HeaderRow />
      </thead>

      {template && <BodyRows />}
    </table>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    template: selectComparisonBoardProductTemplate(state),
    productsData: selectComparisonBoardProducts(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Table)
