import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import BlobUrlImage from '../../Common/BlobUrlImage'
import { selectProductById } from '../../../../state/products/selectors'

const classNames = require('classnames')

function ProductCover({ overlayColor, product }) {
  const coverUrl = product && product.media && _.find(product.media, { collection: 'cover_pictures' })?.url
  const coverFile = product && product.media && _.find(product.media, { collection: 'cover_pictures' })?.file
  const url = coverUrl ?? coverFile

  const imageHtml = useMemo(() => <BlobUrlImage url={url} />, [url])

  return (
    <div className="comparison-board-product-card__cover">
      {imageHtml}
      {overlayColor && (
        <div
          className={classNames(
            'comparison-board-product-card__overlay',
            `comparison-board-product-card__overlay--${overlayColor}`
          )}
          aria-hidden="true"
        />
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    product: selectProductById(state, ownProps.productId),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProductCover)
