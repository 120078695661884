import _ from 'lodash'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import {
  selectComparisonBoardProductTemplate,
  selectIsExpandedModelChangeRow,
} from '../../../../state/comparisonBoard/selectors'
import RowsModelChangeExpander from '../ActionElements/RowsModelChangeExpander'

function Header({ nodeDefinition, environment, template, collapseModelChangeRow, expandModelChangeRow, expanded }) {
  const name = useMemo(() => _.get(nodeDefinition, ['name', environment.defaultLang], ''), [nodeDefinition])

  const parentName = useMemo(() => {
    if (template && nodeDefinition?.parent_id) {
      const parentNodeDefinition = _.find(template.node_definitions, item => item.id === nodeDefinition.parent_id)
      return _.get(parentNodeDefinition, ['name', environment.defaultLang], '')
    }
    return ''
  }, [nodeDefinition])

  const handleClick = e => {
    e.preventDefault()
    if (expanded) {
      collapseModelChangeRow(nodeDefinition.id)
    } else {
      expandModelChangeRow(nodeDefinition.id)
    }
  }

  return (
    <div
      className="comparison-board-table-header-cell__wrapper"
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex="0"
    >
      <div className="comparison-board-table-header-cell__name" title={name}>
        {`${parentName}>${name}`}
      </div>

      <div className="comparison-board-table-header-cell__expander-wrapper">
        <RowsModelChangeExpander
          nodeDefinition={nodeDefinition}
          nodeDefinitionId={nodeDefinition.id}
          expanded={expanded}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    template: selectComparisonBoardProductTemplate(state),
    expanded: selectIsExpandedModelChangeRow(state, ownProps.nodeDefinition?.id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Header)
