import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Icon from '../../../../../../assets/svg/arrow-select.svg'

const classNames = require('classnames')

function RowsExpander({ expanded }) {
  return (
    <button
      type="button"
      className={classNames({
        'comparison-board-table__rows-expander': true,
        'comparison-board-table__rows-expander--expanded': expanded,
      })}
    >
      <Icon width="12" />
    </button>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(RowsExpander)
