import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { toFixed1IfDecimal } from '../../../../../../va-corejs-v3/utils'
import nodeDefinitionTypeMap from '../../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import { selectNode } from '../../../state/comparisonBoard/selectors'
import DifferenceValue from './DifferenceValue'
import ScoreCard from './ScoreCard'
import PercentageValue from './PercentageValue'

function ScoreCell({ node, nodeDefinition, product }) {
  const { type } = nodeDefinition

  const scoreToShow = useMemo(() => {
    if (!node) {
      return null
    }

    if (type === nodeDefinitionTypeMap.perimeter || !node?.is_enabled) {
      return ''
    }

    return toFixed1IfDecimal(node.normalized_score)
  }, [node])

  if (node && (type === nodeDefinitionTypeMap.item || type === nodeDefinitionTypeMap.criterion)) {
    return <ScoreCard product={product} nodeDefinition={nodeDefinition} node={node} />
  }

  return (
    <div className="comparison-board-table__score">
      {node?.is_enabled && (
        <>
          <div className="score__value">{scoreToShow}</div>
          {type !== nodeDefinitionTypeMap.perimeter && (
            <>
              <span style={{ marginLeft: '3px', marginRight: '3px' }}>-</span>
              <PercentageValue node={node} />
            </>
          )}
          <DifferenceValue node={node} nodeDefinition={nodeDefinition} />
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    node: selectNode(state, ownProps.product.product_id, ownProps.nodeDefinition.id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ScoreCell)
