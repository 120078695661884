import React, { useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import RemoveIcon from '../../../../../../../assets/svg/x.svg'
import Dialog from '../../../Common/Dialog'
import { selectProductById } from '../../../../../state/products/selectors'

function RemoveButton({
  productId,
  projectId,
  configurationCategoryId,
  removeProductFromProject,
  texts,
  product,
  environment,
}) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const brandName = product && _.find(product.props, { slug: 'brand' }).value.body[environment.defaultLang]
  const modelName = product && _.find(product.props, { slug: 'model' }).value.body[environment.defaultLang]
  const productName = product && `${brandName} ${modelName}`

  const handleClick = async () => {
    setOpenDeleteDialog(true)
    setConfirmDelete(false)
  }

  const handleClickDialogRemove = async () => {
    setConfirmDelete(true)
  }

  const handleClickConfirmRemove = async () => {
    await removeProductFromProject(productId, projectId, configurationCategoryId)
  }

  return (
    <>
      <button
        className="comparison-board-product-card__button comparison-board-product-card__button--remove"
        onClick={handleClick}
        type="button"
      >
        <RemoveIcon width="10" />
      </button>
      <Dialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onConfirm={confirmDelete ? handleClickConfirmRemove : handleClickDialogRemove}
        closeOnConfirm={false}
        title={`${texts.remove}  ${productName}`}
        text={
          confirmDelete
            ? `${texts.are_you_really_sure_remove} ${productName} ?`
            : `${texts.are_you_sure_remove} ${productName} ?`
        }
        textColor={confirmDelete && 'red'}
        confirmButtonText={texts.remove}
      />
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    templates: state.templates.list,
    configurationCategoryId: state.renaultProjectMode.comparisonBoard.configurationCategoryId,
    projectId: state.renaultProjectMode.comparisonBoard.projectId,
    product: selectProductById(state, ownProps.productId),
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(RemoveButton)
