import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { Scrollbar } from 'react-scrollbars-custom'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import '../../../../styles/node-modal.scss'
import 'swiper/swiper.min.css'
import NegativeIcon from '../../../../../../assets/svg/comment-feedback-negative.svg'
import NeutralIcon from '../../../../../../assets/svg/comment-feedback-neutral.svg'
import PositiveIcon from '../../../../../../assets/svg/comment-feedback-positive.svg'
import commentFeedbackMap from '../../../../../../components/scoring_tree/helper/commentFeedbackMap'

function CommentsCell({ node }) {
  const comments = node.comments ?? []

  const element = useMemo(
    () => (
      <Scrollbar noScrollX noDefaultStyles>
        {comments.map((comment, index) => {
          const { feedback } = comment
          let icon = null

          switch (feedback) {
            case commentFeedbackMap.negative: {
              icon = <NegativeIcon width="14px" />
              break
            }
            case commentFeedbackMap.positive: {
              icon = <PositiveIcon width="14px" />
              break
            }
            default: {
              icon = <NeutralIcon width="14px" />
            }
          }

          return (
            <div key={index} className="node-modal-comment">
              <div className="node-modal-comment__icon">{icon}</div>

              <div className="node-modal-comment__text">{comment.body}</div>
            </div>
          )
        })}
      </Scrollbar>
    ),
    []
  )

  return <div className="node-modal-comments-cell">{element}</div>
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(CommentsCell)
