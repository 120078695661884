import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import BlobUrlImage from '../../Common/BlobUrlImage'
import { selectProductById } from '../../../../state/products/selectors'
import { selectBrandbyProduct } from '../../../../../../store/selectors/scopes'

function ProductBrand({ brand }) {
  const logoUrl = brand && _.first(brand?.media)?.url

  return (
    <div className="comparison-board-product-card__brand">
      <BlobUrlImage url={logoUrl} />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const product = selectProductById(state, ownProps.productId)

  return {
    texts: state.texts.values,
    environment: state.environment,
    product,
    brand: selectBrandbyProduct(state, product),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProductBrand)
