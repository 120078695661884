import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import '../../../../styles/node-modal.scss'
import AngleIcon from '../../../../../../assets/svg/lightbox-breadcrumb.svg'

function Breadcrumbs({ nodeDefinition, environment }) {
  const { lang, defaultLang } = environment

  const trailer = useMemo(() => {
    const { trailer: trailers } = nodeDefinition

    if (typeof trailers[lang] === 'string' || trailers[lang] instanceof String) {
      return trailers[lang].split(' > ')
    }

    if (typeof trailers[defaultLang] === 'string' || trailers[defaultLang] instanceof String) {
      return trailers[defaultLang].split(' > ')
    }

    return []
  }, [lang, defaultLang, nodeDefinition.trailer])

  return (
    <div className="node-modal-breadcrumbs">
      {trailer.map((label, index) => (
        <div className="node-modal-breadcrumbs__item" key={index}>
          <div className="node-modal-breadcrumbs__item-label">{label}</div>
          {index < trailer.length - 1 && (
            <div className="node-modal-breadcrumbs__icon">
              <AngleIcon width="6px" />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Breadcrumbs)
