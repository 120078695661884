import React from 'react'
import { connect } from 'react-redux'
import {
  selectComparisonBoardOption,
  selectComparisonBoardReferenceProduct,
  selectComparisonBoardProducts,
} from '../../../../state/comparisonBoard/selectors'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Switcher from '../../Common/Switcher'
import { RPM_COMPARISON_BOARD_OPTION_DIFFERENCES } from '../../../../consts/comparisonBoard'

function Differences({ value, referenceProduct, toggleComparisonBoardOption, products, texts }) {
  const handleChange = () => {
    toggleComparisonBoardOption(RPM_COMPARISON_BOARD_OPTION_DIFFERENCES)
  }

  return (
    <div className="comparison-board-switcher__wrapper">
      <div className="comparison-board-switcher__label">{texts.differences}</div>
      <Switcher
        id="differences-switcher"
        onChange={handleChange}
        value={value}
        size="small"
        disabled={!referenceProduct || products.length === 0}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    value: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_DIFFERENCES),
    referenceProduct: selectComparisonBoardReferenceProduct(state),
    products: selectComparisonBoardProducts(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Differences)
