import React from 'react'
import { connect } from 'react-redux'
import '../../../../styles/pagination.scss'
import NextIcon from '../../../../../../assets/svg/pagination-next.svg'
import PrevIcon from '../../../../../../assets/svg/pagination-prev.svg'
import * as renaultProjectModeActionCreators from '../../../../state/actions'

function Pagination({ isLoading, pagination, updateSearchProductsPaginationParams, searchProducts, texts }) {
  const { page, total, perPage } = pagination

  const handlePrevClick = async () => {
    updateSearchProductsPaginationParams(page - 1)
    await searchProducts()
  }

  const handleNextClick = async () => {
    updateSearchProductsPaginationParams(page + 1)
    await searchProducts()
  }

  return (
    <div className="products-search-modal-pagination search-pagination search-pagination">
      <div className="search-pagination__prev">
        <button type="button" disabled={isLoading || page === 1} onClick={handlePrevClick}>
          <PrevIcon />
          <span>{texts.previous_results}</span>
        </button>
      </div>
      <div className="search-pagination__current-page">{pagination.page}</div>
      <div className="search-pagination__next">
        <button type="button" disabled={isLoading || page * perPage >= total} onClick={handleNextClick}>
          <span>{texts.next_results}</span>
          <NextIcon />
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    isLoading: state.renaultProjectMode.searchProducts.isLoading,
    pagination: state.renaultProjectMode.searchProducts.pagination,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Pagination)
