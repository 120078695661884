import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import DotsIcon from '../../../../../../../assets/svg/dots.svg'

function MenuButton({ onClick }) {
  return (
    <button
      className="comparison-board-product-card__button comparison-board-product-card__button--menu"
      onClick={onClick}
      type="button"
    >
      <DotsIcon width="12" />
    </button>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    templates: state.templates.list,
    configurationCategoryId: state.renaultProjectMode.comparisonBoard.configurationCategoryId,
    projectId: state.renaultProjectMode.comparisonBoard.projectId,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(MenuButton)
