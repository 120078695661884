import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import WeightIcon from '../../../../../assets/svg/weight.svg'
import {
  selectComparisonBoardProductById,
  selectComparisonBoardProductTemplate,
} from '../../../state/comparisonBoard/selectors'
import { calcCriterionCalculatedWeight } from '../../../services/product'

const classNames = require('classnames')

function WeightValue({ className, nodeDefinition, template, product }) {
  const nodeDefinitions = template.node_definitions

  const value = useMemo(() => {
    let calculatedWeight

    if (nodeDefinition.type === 'criterion') {
      calculatedWeight = calcCriterionCalculatedWeight(nodeDefinition, nodeDefinitions, product.nodes)
    }

    // Ticket VAV3-1164
    // Calculated weight (bottom right corner) on item
    // Sum of calculated weight of its criteria

    if (nodeDefinition.type === 'item') {
      calculatedWeight = 0
      nodeDefinition.children_ids.forEach(childId => {
        const childNodeDefinition = nodeDefinitions[childId]
        if (childNodeDefinition.type === 'criterion') {
          calculatedWeight += calcCriterionCalculatedWeight(childNodeDefinition, nodeDefinitions, product.nodes)
        }
      })
    }

    return calculatedWeight?.toFixed(2)
  }, [template])

  return (
    <div className={classNames(className, 'comparison-board-table__weight')}>
      <div className="comparison-board-table__weight-icon">
        <WeightIcon width="12px" />
      </div>
      {value && <div className="comparison-board-table__weight-value">{value}</div>}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    template: selectComparisonBoardProductTemplate(state),
    product: selectComparisonBoardProductById(state, ownProps.node.product_id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(WeightValue)
