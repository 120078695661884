import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Select from '../../Common/Select'
import ModelChangeModal from './ModelChangeModal'

function ModelChangeSelect({ modelChangeItems, setModelChangeItemSelectedId, texts }) {
  const modelChangeItemsOptions = Object.values(modelChangeItems).map(item => ({
    value: item.id,
    label: item.value,
  }))

  const onChange = id => {
    setModelChangeItemSelectedId(id)
  }

  return (
    <div className="comparison-board-select__wrapper">
      <ModelChangeModal />
      <Select
        id="model-change-select"
        options={modelChangeItemsOptions}
        width={97}
        onChange={onChange}
        placeholder={texts.select_tag}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    modelChangeItems: state.renaultProjectMode.comparisonBoard.modelChangeItems,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ModelChangeSelect)
