import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { calcCriterionCalculatedWeight } from '../../../services/product'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import {
  selectComparisonBoardProductById,
  selectComparisonBoardProductTemplate,
} from '../../../state/comparisonBoard/selectors'

const classNames = require('classnames')

function ScoreContibutionValue({ className, node, nodeDefinition, template, product }) {
  /* const value = useMemo(() => {
    const nodeDefinition = _.find(template.node_definitions, _n => _n.id === node.node_definition_id)
    const fullValue = calcScoreContribution(node, nodeDefinition, product.nodes)
    return fullValue === Math.round(fullValue) ? fullValue : fullValue.toFixed(2)
  }, [node]) */

  const nodeDefinitions = template.node_definitions

  const value = useMemo(() => {
    let calculatedWeight
    let scoreContibution

    if (nodeDefinition?.type === 'criterion') {
      // Ticket VAV3-1164
      // Formula for score contribution
      // (((calculated weight / 100) * 500) / 4) * score)

      calculatedWeight = calcCriterionCalculatedWeight(nodeDefinition, nodeDefinitions, product.nodes)
      if (calculatedWeight && nodeDefinition && node)
        scoreContibution = (((calculatedWeight / 100) * 500) / 4) * node.score
    }

    return scoreContibution?.toFixed(2)
  }, [template, node])

  return (
    <div className={classNames(className, 'comparison-board-table__score-contribution')}>
      <span className="comparison-board-table__score-contribution-value">{value}</span>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    template: selectComparisonBoardProductTemplate(state),
    product: selectComparisonBoardProductById(state, ownProps.node.product_id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ScoreContibutionValue)
