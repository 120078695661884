import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import ClearButton from './ClearButton'
import ValidateButton from './ValidateButton'

function Footer({ onConfirm }) {
  return (
    <div className="products-search-modal__footer">
      <div className="products-search-modal__footer-inner-wrapper">
        <ClearButton />
        <ValidateButton onConfirm={onConfirm} />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Footer)
