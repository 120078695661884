import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Dialog } from '@material-ui/core'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Button from '../../Common/Button'
import commentFeedbackMap from '../../../../../../components/scoring_tree/helper/commentFeedbackMap'
import CommentSendBtn from '../../../../../../assets/svg/comment-send.svg'
import { mongoObjectId } from '../../../../../../../va-corejs-v3/utils'

const classNames = require('classnames')

function CommentModal({
  open,
  setOpen,
  texts,
  comment,
  nodeDefinition,
  node,
  product,
  environment,
  updateComparisonBoardProductComment,
  authentication,
}) {
  const [commentEdited, setCommentEdited] = useState(comment)
  const [commentEditedFeedback, setCommentEditedFeedback] = useState()
  const [commentEditedText, setCommentEditedText] = useState()

  // new comment
  // new comment locale

  const [commentEditedLocale, setCommentEditedLocale] = useState()
  const currentLocale = _.find(environment.locales, locale => locale.code === environment.lang)

  const newComment = {
    id: null,
    body: null,
    feedback: null,
    node_id: node.id,
    node_definition_id: nodeDefinition.id,
    locale: currentLocale,
    author: authentication?.user,
  }

  useEffect(() => {
    setCommentEdited(comment)
    setCommentEditedFeedback(comment?.feedback)
    setCommentEditedLocale(comment?.locale)
  }, [comment])

  const title = useMemo(() => {
    let titleParts = nodeDefinition?.trailer?.[environment.defaultLang].split(' > ')

    if (titleParts?.length > 2) {
      titleParts = titleParts.slice(2)
      return `../${titleParts?.join(' ')}`
    }

    return titleParts?.join(' ')
  }, [nodeDefinition])

  const handleCancel = () => {
    setOpen(false)
  }

  const handleCommentChange = e => {
    setCommentEditedText(e.target.value)
  }

  const handleFeedbackSelected = (e, feedback) => {
    setCommentEditedFeedback(feedback)
  }

  const handleSendCommentClicked = async () => {
    const isEdited = commentEdited && (commentEditedText || commentEditedFeedback || commentEditedLocale)
    const isAdded = !commentEdited && (commentEditedText || commentEditedFeedback || commentEditedLocale)

    if (isEdited && product) {
      await updateComparisonBoardProductComment(product, node, commentEdited.id, {
        body: commentEditedText,
        feedback: commentEditedFeedback,
        locale: commentEditedLocale ?? currentLocale,
      })
    } else if (isAdded) {
      newComment.id = mongoObjectId()
      newComment.body = commentEditedText
      newComment.feedback = commentEditedFeedback
      newComment.locale = commentEditedLocale ?? currentLocale

      await updateComparisonBoardProductComment(product, node, null, newComment)
    }

    setOpen(false)
  }

  const handleCommentLangSelected = e => {
    const localeSelected = _.find(environment.locales, locale => locale.code === e.target.value)
    setCommentEditedLocale(localeSelected)
  }

  return (
    <Dialog
      PaperProps={{
        className: 'score-card__comment-modal-wrapper',
        style: {
          width: 380,
          padding: 15,
        },
      }}
      open={open}
      onClose={handleCancel}
    >
      <div className="score-card__comment-modal__title">{title}</div>
      <div className="score-card__comment-modal__textarea">
        <textarea defaultValue={commentEdited?.body} onChange={handleCommentChange} />
        <select
          className="va_select sctree_rp_add_edit_comment_lang_select"
          value={commentEditedLocale?.code ?? currentLocale.code}
          onChange={handleCommentLangSelected}
        >
          {environment.locales.map((locale, index) => {
            return (
              <option key={index} value={locale.code}>
                {locale.code}
              </option>
            )
          })}
        </select>
      </div>
      <div className="score-card__comment-modal__actions">
        <Button label={texts.cancel} size="big" width="50%" color="white" onClick={handleCancel} />
        <div className="sctree_rp_add_edit_comment_feedback">
          <div
            className={classNames(
              'sctree_rp_add_edit_comment_feedback_btn',
              'sctree_rp_add_edit_comment_feedback_positive',
              {
                sctree_rp_add_edit_comment_feedback_selected: commentEditedFeedback === commentFeedbackMap.positive,
              }
            )}
            role="button"
            tabIndex={0}
            onClick={e => handleFeedbackSelected(e, commentFeedbackMap.positive)}
            onKeyPress={e => handleFeedbackSelected(e, commentFeedbackMap.positive)}
          />
          <div
            className={classNames(
              'sctree_rp_add_edit_comment_feedback_btn',
              'sctree_rp_add_edit_comment_feedback_neutral',
              {
                sctree_rp_add_edit_comment_feedback_selected:
                  commentEditedFeedback !== commentFeedbackMap.negative &&
                  commentEditedFeedback !== commentFeedbackMap.positive,
              }
            )}
            role="button"
            tabIndex={0}
            onClick={e => handleFeedbackSelected(e, commentFeedbackMap.neutral)}
            onKeyPress={e => handleFeedbackSelected(e, commentFeedbackMap.neutral)}
          />
          <div
            className={classNames(
              'sctree_rp_add_edit_comment_feedback_btn',
              'sctree_rp_add_edit_comment_feedback_negative',
              {
                sctree_rp_add_edit_comment_feedback_selected: commentEditedFeedback === commentFeedbackMap.negative,
              }
            )}
            role="button"
            tabIndex={0}
            onClick={e => handleFeedbackSelected(e, commentFeedbackMap.negative)}
            onKeyPress={e => handleFeedbackSelected(e, commentFeedbackMap.negative)}
          />
        </div>
        <div
          className="sctree_rp_item_node_exp_send_comment_btn_container"
          role="button"
          tabIndex={0}
          onClick={e => {
            handleSendCommentClicked(e)
          }}
          onKeyPress={e => {
            handleSendCommentClicked(e)
          }}
        >
          <CommentSendBtn className="theme_svg" />
        </div>
      </div>
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(CommentModal)
