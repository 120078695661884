import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import '../../../../styles/node-modal.scss'
import 'swiper/swiper.min.css'
import BlobUrlImage from '../../Common/BlobUrlImage'
import { selectNodeMedia } from '../../../../state/products/selectors'

function CoverCell({ media, height, index, product, onReachEnd }) {
  const [coverUrl, setCoverUrl] = useState(null)
  const orderIndexes = useMemo(() => _.map(media, m => m.order), [media])
  const selectedMedia = useMemo(
    () => (media.length > 0 && orderIndexes[index] ? _.find(media, m => m.order === orderIndexes[index]) : null),
    [media, index]
  )

  useEffect(() => {
    if (index === orderIndexes.length - 1 || selectedMedia === null) {
      onReachEnd(product.product_id)
    }
  }, [index, selectedMedia])

  useEffect(() => {
    if (selectedMedia) {
      const url = selectedMedia.url || selectedMedia.file
      if (url !== coverUrl) {
        setCoverUrl(url)
      }
    }
  }, [selectedMedia])

  const imageHtml = useMemo(() => <BlobUrlImage url={coverUrl} />, [coverUrl])

  return (
    <div className="node-modal-cover-cell">
      <div className="node-modal-cover-cell__inner-wrapper" style={{ height }}>
        {imageHtml}
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    media: selectNodeMedia(state, ownProps.product.product_id, ownProps.node.id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(CoverCell)
