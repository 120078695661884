import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import '../../../styles/switchers.scss'

const classNames = require('classnames')

function Switcher({ id, onChange, value, defaultValue, size, disabled }) {
  const [checked, setChecked] = useState(defaultValue)

  useEffect(() => {
    if (value !== null) {
      setChecked(value)
    }
  }, [value])

  const handleChange = () => {
    if (disabled) {
      return
    }

    const newValue = !checked

    if (value === null) {
      // Uncontrolled
      setChecked(newValue)
    }

    onChange(newValue)
  }

  return (
    <label
      htmlFor={id}
      className={classNames({ 'switcher--ios': true, 'switcher--disabled': disabled }, 'switcher', `switcher--${size}`)}
    >
      <input id={id} className="swicther__input" type="checkbox" checked={checked} onChange={handleChange} />
      <span className="switcher__slider" />
    </label>
  )
}

Switcher.defaultProps = {
  value: null,
  defaultValue: false,
  size: 'medium',
  disabled: false,
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Switcher)
