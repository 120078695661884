import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import { RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE } from '../../../../consts/comparisonBoard'
import { selectComparisonBoardOption, selectComparisonBoardProducts } from '../../../../state/comparisonBoard/selectors'
import ChartIcon from '../../../../../../assets/svg/chart.svg'
import ChartModal from './ChartModal'

function Chart({ product, modelChangeOption, disable, productsData }) {
  const [open, setOpen] = React.useState(false)

  const referenceProduct = useMemo(() => {
    return productsData.find(boardProduct => boardProduct.reference)
  }, [productsData])

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <>
      {modelChangeOption && (
        <div className="comparison-board-product-card__model-change-chart-wrapper">
          {!product.reference && referenceProduct && (
            <button
              type="button"
              className="comparison-board-product-card__model-change-chart-button"
              onClick={handleOpen}
              disabled={disable}
            >
              <ChartIcon />
            </button>
          )}
        </div>
      )}
      {open && <ChartModal open={open} onClose={handleClose} product={product} />}
    </>
  )
}

const mapStateToProps = state => {
  return {
    modelChangeOption: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE),
    productsData: selectComparisonBoardProducts(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Chart)
