import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import '../../../styles/products-search-modal.scss'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import Header from './Header'
import ModalBody from './Body'
import Footer from './Footer'
import SpinnerFiller from '../Common/SpinnerFiller'
import { selectProjectMappingWithoutConfCategory } from '../../../state/projects/selectors'

function SearchProductsModal({
  title,
  onClose,
  searchProducts,
  onConfirm,
  preSelectedIds,
  isSyncing,
  initSelectedProducts,
  selectedIds,
  updateSearchProductsParams,
  project,
}) {
  const [initialized, setInitialized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const asyncSearch = async () => {
    setIsLoading(true)
    await searchProducts()
    setIsLoading(false)
  }

  useEffect(() => {
    async function _init() {
      await initSelectedProducts(preSelectedIds)

      updateSearchProductsParams({
        projectId: project?.id,
        includedProductIds: preSelectedIds,
      })
      setInitialized(true)
    }

    _init()

    return () => {
      updateSearchProductsParams({
        projectId: null,
        includedProductIds: null,
      })
    }
  }, [])

  useEffect(() => {
    async function _search() {
      await asyncSearch()
    }

    if (initialized) {
      // initialized check aim to avoid double search during mounting
      _search()
    }
  }, [initialized])

  useEffect(() => {
    async function _search() {
      await asyncSearch()
    }

    if (initialized && (!selectedIds || selectedIds.length < 2)) {
      // initialized check aim to avoid double search during mounting
      _search()
    }
  }, [selectedIds])

  const handleConfirm = async ids => {
    await onConfirm(ids)
    onClose()
  }

  return (
    <div className="products-search-modal__wrapper">
      <div className="products-search-modal">
        <Header title={title} onCloseClick={onClose} />
        <ModalBody />
        <Footer onConfirm={handleConfirm} />
        {(isSyncing || isLoading) && <SpinnerFiller width="28" />}
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    isLoading: state.renaultProjectMode.searchProducts.isLoading,
    selectedIds: state.renaultProjectMode.searchProducts.selectedIds,
    mappingWithoutConfCategory: selectProjectMappingWithoutConfCategory(state, ownProps.project?.id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(SearchProductsModal)
