import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Dialog from '../../Common/Dialog'
import Button from '../../Common/Button'
import styles from '../../../../styles/ModelChangeDialog.module.scss'

import EditIcon from '../../../../../../assets/svg/pen-sticky.svg'
import DeleteIcon from '../../../../../../assets/svg/trash.svg'
import AddIcon from '../../../../../../assets/svg/plus-alt.svg'
import { mongoObjectId } from '../../../../../../../va-corejs-v3/utils'

const classNames = require('classnames')

function ModelChangeModal({ texts, modelChangeItems, updateComparisonBoardModelChangeItems }) {
  const [openMCDialog, setOpenMCDialog] = useState(false)
  const [openMCEditDialog, setOpenMCEditDialog] = useState(false)
  const [openMCAddDialog, setOpenMCAddDialog] = useState(false)
  const [openMCDeleteDialog, setOpenMCDeleteDialog] = useState(false)
  const [items, setItems] = useState({})
  const [itemSelected, setItemSelected] = useState()
  const [itemEditedTag, setItemEditedTag] = useState()
  const [itemAddedTag, setItemAddedTag] = useState()

  useEffect(() => {
    setItems({ ...modelChangeItems })
  }, [modelChangeItems])

  const editItem = () => {
    const updatedItems = { ...items }
    const updatedItem = { ...updatedItems[itemSelected.id] }
    updatedItem.value = itemEditedTag
    updatedItems[itemSelected.id] = updatedItem
    setItems(updatedItems)
    setOpenMCDialog(true)
    setItemSelected()
  }

  const addItem = () => {
    const updatedItems = { ...items }
    const newItemId = mongoObjectId()
    updatedItems[newItemId] = {
      id: newItemId,
      value: itemAddedTag,
      default: false,
    }
    setItems(updatedItems)
    setOpenMCDialog(true)
  }

  const removeItem = () => {
    const updatedItems = { ...items }
    delete updatedItems[itemSelected.id]
    setItems(updatedItems)
    setOpenMCDialog(true)
    setItemSelected()
  }

  const handleSave = async () => {
    updateComparisonBoardModelChangeItems(items)
    setOpenMCDialog(false)
  }

  return (
    <>
      <div className={classNames('comparison-board-select__label', styles.ModelChangeDialog_buttonOpenWrapper)}>
        <Button
          className={styles.ModelChangeDialog_buttonOpen}
          onClick={() => setOpenMCDialog(true)}
          iconAfter={<EditIcon />}
          label={texts.manage_tags}
          formatted={false}
        />
      </div>

      {/* MC Dialog */}
      <Dialog
        open={openMCDialog}
        setOpen={setOpenMCDialog}
        onConfirm={handleSave}
        onCancel={() => setItems({ ...modelChangeItems })}
        closeOnConfirm={false}
        title={texts.menage_model_change_tags}
        confirmButtonText={texts.save}
        animation={false}
        className={styles.ModelChangeDialog}
      >
        <div className={styles.ModelChangeDialog_content}>
          <Button
            className={styles.ModelChangeDialog_addButton}
            onClick={() => {
              setOpenMCDialog(false)
              setOpenMCAddDialog(true)
            }}
            color="secondary"
            label={texts.add_tag}
            iconBefore={<AddIcon />}
            simple
          />
          <div className={styles.ModelChangeDialog_list}>
            {items &&
              Object.values(items).map(item =>
                item.value ? (
                  <div
                    key={item.id}
                    className={classNames(styles.ModelChangeDialog_list_item, {
                      [styles.ModelChangeDialog_list_item_default]: item.default,
                    })}
                  >
                    {item.value}
                    {item && (
                      <div className={styles.ModelChangeDialog_list_item_actions}>
                        {item.default ? (
                          texts.default
                        ) : (
                          <>
                            <Button
                              size="big"
                              onClick={() => {
                                setItemSelected(item)
                                setOpenMCDialog(false)
                                setOpenMCEditDialog(true)
                              }}
                              color="secondary"
                              icon={<EditIcon />}
                            />
                            <Button
                              size="big"
                              onClick={() => {
                                setItemSelected(item)
                                setOpenMCDialog(false)
                                setOpenMCDeleteDialog(true)
                              }}
                              color="secondary"
                              icon={<DeleteIcon />}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                ) : null
              )}
          </div>
        </div>
      </Dialog>

      {/* Edit MC */}
      <Dialog
        open={openMCEditDialog}
        setOpen={setOpenMCEditDialog}
        onConfirm={editItem}
        onCancel={() => {
          setOpenMCDialog(true)
          setItemSelected()
          setItemEditedTag()
        }}
        title={`${texts.edit_model_change_tag} ${itemSelected?.value}`}
        confirmButtonText={texts.save}
        cancelButtonText={texts.button_back}
        animation={false}
      >
        <div className={styles.ModelChangeDialog_content}>
          <div className={styles.ModelChangeDialog_form}>
            <div className={styles.ModelChangeDialog_form_label}>{texts.tag_name}</div>
            <input
              type="text"
              className={styles.ModelChangeDialog_form_input}
              onChange={e => setItemEditedTag(e.target.value)}
              value={itemEditedTag ?? itemSelected?.value}
            />
          </div>
        </div>
      </Dialog>

      {/* Delete MC */}
      <Dialog
        open={openMCDeleteDialog}
        setOpen={setOpenMCDeleteDialog}
        onConfirm={removeItem}
        onCancel={() => {
          setOpenMCDialog(true)
          setItemSelected()
        }}
        title={`${texts.delete} ${itemSelected?.value}`}
        text={`${texts.are_you_sure_delete} ${itemSelected?.value} ?`}
        confirmButtonText={texts.save}
        cancelButtonText={texts.button_back}
        animation={false}
      />

      {/* Add MC */}
      <Dialog
        open={openMCAddDialog}
        setOpen={setOpenMCAddDialog}
        onConfirm={addItem}
        onCancel={() => {
          setOpenMCDialog(true)
          setItemSelected()
          setItemAddedTag()
        }}
        title={texts.create_model_change_tag}
        confirmButtonText={texts.save}
        cancelButtonText={texts.button_back}
        animation={false}
      >
        <div className={styles.ModelChangeDialog_content}>
          <div className={styles.ModelChangeDialog_form}>
            <div className={styles.ModelChangeDialog_form_label}>{texts.tag_name}</div>
            <input
              type="text"
              className={styles.ModelChangeDialog_form_input}
              onChange={e => setItemAddedTag(e.target.value)}
            />
          </div>
        </div>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    modelChangeItems: state.renaultProjectMode.comparisonBoard.modelChangeItems,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ModelChangeModal)
