import React, { useState } from 'react'
import { connect } from 'react-redux'
import { MenuItem, Select as MuiSelect } from '@material-ui/core'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import Arrow from '../../../../../assets/svg/arrow-select.svg'
import styles from '../../../styles/Select.module.scss'

const classNames = require('classnames')

const SelectIcon = props => (
  <div {...props}>
    <Arrow />
  </div>
)

function Select({ options, className, onChange, defaultValue, width, texts, placeholder }) {
  const [value, setValue] = useState(defaultValue ?? '')

  const handleChange = e => {
    const newValue = e.target.value
    setValue(newValue)
    if (onChange) onChange(newValue)
  }

  return (
    <div className={classNames(styles.Select, className)} style={{ width }}>
      <MuiSelect
        className={styles.Select_inner}
        value={value}
        onChange={handleChange}
        variant="standard"
        IconComponent={SelectIcon}
        displayEmpty
        MenuProps={{
          PaperProps: {
            className: styles.Select_menu,
          },
        }}
      >
        <MenuItem className={styles.Select_menu_item} value="">
          {placeholder ?? texts.none}
        </MenuItem>
        {options &&
          options.map((menuItem, index) => (
            <MenuItem key={index} className={styles.Select_menu_item} value={menuItem.value}>
              {menuItem.label}
            </MenuItem>
          ))}
      </MuiSelect>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Select)
