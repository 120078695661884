import React, { useState } from 'react'
import { connect } from 'react-redux'
import Multiselect from 'multiselect-react-dropdown'
import * as actionCreators from '../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import { selectComparisonBoardProductTemplate } from '../../../state/comparisonBoard/selectors'
import Arrow from '../../../../../assets/svg/arrow-select.svg'

function FilterCriteria({ texts, template, addSelectedCriterion, removeSelectedCriterion }) {
  const [selectedOptionsNum, setSelectedOptionsNum] = useState(0)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const nodeDefinitions = template?.node_definitions
  const criteriaTypes = {}
  if (nodeDefinitions) {
    Object.values(nodeDefinitions)
      .filter(node => node.type === 'criterion')
      .forEach(node => {
        if (node?.criterion_template?.type)
          criteriaTypes[node.criterion_template.type.id] = node.criterion_template.type.label?.en
      })
  }
  const options = Object.keys(criteriaTypes).map(id => ({ id, name: criteriaTypes[id] }))

  return (
    <div className="panel__filter-criterion">
      <div
        onBlur={() => setIsMenuOpen(false)}
        onClick={() => setIsMenuOpen(true)}
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
      >
        <Multiselect
          displayValue="name"
          placeholder={
            selectedOptionsNum ? `${selectedOptionsNum} ${texts.criteria_selected}` : texts.select_criteria_to_filter
          }
          onSelect={(selectedList, item) => {
            addSelectedCriterion(item.id)
            setSelectedOptionsNum(selectedList.length)
          }}
          onRemove={(selectedList, item) => {
            removeSelectedCriterion(item.id)
            setSelectedOptionsNum(selectedList.length)
          }}
          hideSelectedList
          options={options}
          showCheckbox
        />
      </div>
      <div
        className="panel__filter-criterion__arrow"
        style={{ transform: isMenuOpen ? 'rotate(180deg)' : 'rotate(0)', pointerEvents: 'none' }}
      >
        <Arrow />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    template: selectComparisonBoardProductTemplate(state),
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(FilterCriteria)
