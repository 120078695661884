import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import '../../../../styles/node-modal.scss'
import 'swiper/swiper.min.css'
import { selectComparisonBoardProducts } from '../../../../state/comparisonBoard/selectors'
import CoverCell from './CoverCell'
import ArrowLeftIcon from '../../../../../../assets/svg/lightbox-arrow-big-left.svg'
import ArrowRightIcon from '../../../../../../assets/svg/lightbox-arrow-big-right.svg'
import {
  RPM_COMPARISON_BOARD_NODE_MODAL_COLUMN_MARGIN,
  RPM_COMPARISON_BOARD_NODE_MODAL_COLUMN_MIN_WIDTH,
  RPM_COMPARISON_BOARD_NODE_MODAL_COVER_RATIO,
} from '../../../../consts/comparisonBoard'

function CoversRow({ productsData, nodes }) {
  const ref = useRef(null)
  const [width, setWidth] = useState(0)
  const [cellHeight, setCellHeight] = useState(0)
  const [mediaIndex, setMediaIndex] = useState(0)
  const [allProductsReachedEnd, setAllProductsReachedEnd] = useState(false)
  const [productsReachedEnd, setProductsReachedEnd] = useState({})

  const calcWidth = () => {
    setWidth(ref.current.offsetWidth)
  }

  useEffect(() => {
    calcWidth()

    window.addEventListener('resize', calcWidth)
    // remove the event listener before the component gets unmounted
    return () => window.removeEventListener('resize', calcWidth)
  }, [])

  useEffect(() => {
    let cellWidth = width > 0 ? width / productsData.length - RPM_COMPARISON_BOARD_NODE_MODAL_COLUMN_MARGIN : 0
    cellWidth =
      cellWidth > RPM_COMPARISON_BOARD_NODE_MODAL_COLUMN_MIN_WIDTH
        ? cellWidth
        : RPM_COMPARISON_BOARD_NODE_MODAL_COLUMN_MIN_WIDTH

    setCellHeight(cellWidth > 0 ? cellWidth / RPM_COMPARISON_BOARD_NODE_MODAL_COVER_RATIO : 0)
  }, [width])

  const handleElementReachedEnd = id => {
    productsReachedEnd[id] = true
    setProductsReachedEnd(productsReachedEnd)

    // Check if all products have reached the end of the media
    let value = true

    _.each(productsData, p => {
      if (!productsReachedEnd[p.product_id] || productsReachedEnd[p.product_id] === false) {
        value = false
      }
    })

    setAllProductsReachedEnd(value)
  }

  const elements = useMemo(() => {
    const array = []

    _.each(productsData, product => {
      const node = _.find(nodes, _node => _node.product_id === product.product_id)
      array.push(
        <CoverCell
          key={node.id}
          product={product}
          node={node}
          height={cellHeight}
          index={mediaIndex}
          onReachEnd={handleElementReachedEnd}
        />
      )
    })

    return array
  }, [cellHeight, mediaIndex])

  const handlePrevClicked = () => {
    if (mediaIndex > 0) {
      setMediaIndex(mediaIndex - 1)
      setProductsReachedEnd({})
      setAllProductsReachedEnd(false)
    }
  }

  const handleNextClicked = () => {
    if (!allProductsReachedEnd) {
      setMediaIndex(mediaIndex + 1)
    }
  }

  return (
    <div className="node-modal-covers-row">
      <div className="node-modal-covers-row__list" ref={ref}>
        {elements}
      </div>

      <button
        type="button"
        className="node-modal-covers__button node-modal-covers__button--prev"
        onClick={handlePrevClicked}
        disabled={mediaIndex === 0}
      >
        <ArrowLeftIcon />
      </button>

      <button
        type="button"
        className="node-modal-covers__button node-modal-covers__button--next"
        onClick={handleNextClicked}
        disabled={allProductsReachedEnd}
      >
        <ArrowRightIcon />
      </button>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    productsData: selectComparisonBoardProducts(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(CoversRow)
