import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import CommentRow from './CommentRow'
import PlusIcon from '../../../../../../assets/svg/plus-alt.svg'

const classNames = require('classnames')

function CommentsPopup({ comments, setCommentToEdit, setOpenCommentModal, isEditable }) {
  const handleCommentEdit = comment => {
    setOpenCommentModal(true)
    setCommentToEdit(comment)
  }
  const handleCommentAdd = () => {
    setOpenCommentModal(true)
    setCommentToEdit()
  }

  return (
    <div className="score-card__comment-popup-wrapper">
      <div className="score-card__comment-popup">
        {isEditable && (
          <div
            className="score-card__comment-popup__add-comment"
            onClick={() => handleCommentAdd()}
            role="button"
            tabIndex={0}
            onKeyDown={() => handleCommentAdd()}
          >
            <PlusIcon /> Add Comment
          </div>
        )}
        <div className="score-card__comment-popup-inner">
          {comments.map(comment => (
            <div
              className={classNames('score-card__comment-popup-inner__comment', {
                'score-card__comment-popup-inner__comment--editable': isEditable,
              })}
              key={comment.id}
              onClick={() => handleCommentEdit(comment)}
              role="button"
              tabIndex={0}
              onKeyDown={() => handleCommentEdit(comment)}
            >
              <CommentRow comment={comment} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(CommentsPopup)
