import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styles from '../../../styles/Buttons.module.scss'

const classNames = require('classnames')

function ToggleButton({ onChange, color = 'primary', value, initialValue = false, icon, className }) {
  const [isToggled, setIsToggled] = useState(initialValue)
  const [isClicked, setIsClicked] = useState(false)

  useEffect(() => {
    // if value is changed without click use contrelled way
    if (value !== null && !isClicked) setIsToggled(value)
    else setIsClicked(false)
  }, [value])

  const handleCLick = e => {
    e.stopPropagation()
    setIsClicked(true)
    const toggled = !isToggled
    setIsToggled(toggled)
    if (onChange) onChange(toggled)
  }

  return (
    <button
      className={classNames(
        {
          [styles[`ToggleButton___${color}`]]: color,
        },
        {
          [styles.ToggleButton___active]: isToggled,
          active: isToggled,
        },
        styles.ToggleButton,
        className
      )}
      type="button"
      onClick={handleCLick}
    >
      {icon && <div className={styles.ToggleButton_icon}>{icon}</div>}
    </button>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps)(ToggleButton)
