import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import HeartIcon from '../../../../../../../assets/svg/heart-inactive.svg'

const classNames = require('classnames')

function ReferenceButton({ productId, active, toggleComparisonBoardProductReference }) {
  const handleClick = () => {
    toggleComparisonBoardProductReference(productId)
  }

  return (
    <button
      className={classNames(
        'comparison-board-product-card__button',
        'comparison-board-product-card__button--reference',
        {
          'comparison-board-product-card__button--active': active,
        }
      )}
      onClick={handleClick}
      type="button"
    >
      <HeartIcon width="12" />
    </button>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    templates: state.templates.list,
    configurationCategoryId: state.renaultProjectMode.comparisonBoard.configurationCategoryId,
    projectId: state.renaultProjectMode.comparisonBoard.projectId,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ReferenceButton)
