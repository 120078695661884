import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import {
  selectComparisonBoardProducts,
  selectComparisonBoardProductTemplate,
  selectNodeDefinitionForModal,
} from '../../../../state/comparisonBoard/selectors'
import '../../../../styles/node-modal.scss'
import CloseIcon from '../../../../../../assets/svg/x.svg'
import Breadcrumbs from './Breadcrumbs'
import CommentsRow from './CommentsRow'
import CriteriaRow from './CriteriaRow'
import HeadersRow from './HeadersRow'
import CoversRow from './CoversRow'
import {
  RPM_COMPARISON_BOARD_NODE_MODAL_COLUMN_MARGIN,
  RPM_COMPARISON_BOARD_NODE_MODAL_COLUMN_MIN_WIDTH,
} from '../../../../consts/comparisonBoard'
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions'

function NodeModal({ nodeDefinition, toggleNodeModal, productsData, template }) {
  const { width: windowWidth } = useWindowDimensions()

  const minContainerWidth =
    (RPM_COMPARISON_BOARD_NODE_MODAL_COLUMN_MARGIN + RPM_COMPARISON_BOARD_NODE_MODAL_COLUMN_MIN_WIDTH) *
      productsData.length +
    76 // 76 is the padding of the containers

  const nodes = useMemo(() => {
    const _nodes = _.map(productsData, product =>
      _.find(product.nodes, _node => _node.node_definition_id === nodeDefinition.id)
    )
    return _.keyBy(_nodes, 'product_id')
  }, [])

  const criteriaNodeDefinitions = useMemo(
    () =>
      _.filter(
        template.node_definitions,
        _node => _node._left > nodeDefinition._left && _node._right < nodeDefinition._right
      ),
    []
  )

  const criteriaNodes = useMemo(() => {
    const _nodes = []

    _.each(criteriaNodeDefinitions, criterionNodeDefinition => {
      _.each(productsData, product => {
        _nodes.push(_.find(product.nodes, _node => _node.node_definition_id === criterionNodeDefinition.id))
      })
    })

    return _.groupBy(_nodes, 'node_definition_id')
  }, [criteriaNodeDefinitions])

  return (
    <div
      className={classNames('node-modal-container', {
        'node-modal-container--scrollbar': windowWidth < minContainerWidth,
      })}
    >
      <button
        className="node-modal__close-button"
        type="button"
        onClick={() => {
          toggleNodeModal(null)
        }}
      >
        <CloseIcon width="11px" />
      </button>

      <div className="node-modal__inner-wrapper">
        <div className="node-modal__header">
          <Breadcrumbs nodeDefinition={nodeDefinition} />
          <HeadersRow nodeDefinition={nodeDefinition} nodes={nodes} />
          <CoversRow nodes={nodes} />
        </div>

        <div className="node-modal__comments">
          <CommentsRow nodes={nodes} />
        </div>

        <div className="node-modal__criteria">
          <div className="node-modal-criteria__inner-wrapper">
            {criteriaNodeDefinitions.map((criterionNodeDefinition, index) => (
              <CriteriaRow
                key={index}
                nodeDefinition={criterionNodeDefinition}
                nodes={criteriaNodes[criterionNodeDefinition.id]}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    nodeDefinition: selectNodeDefinitionForModal(state),
    productsData: selectComparisonBoardProducts(state),
    template: selectComparisonBoardProductTemplate(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(NodeModal)
