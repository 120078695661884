import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Row from './Row'
import {
  selectComparisonBoardProductTemplate,
  selectComparisonBoardProducts,
} from '../../../../state/comparisonBoard/selectors'
import nodeDefinitionTypeMap from '../../../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import { hasChildrenCriterionAnyModelChange } from '../../../../services/modelChange'

function ModelChange({
  template,
  productsData,
  texts,
  modelChangeData,
  expandedModelChangeRows,
  expandModelChangeRow,
}) {
  const [familyNodeDefinitions, setFamilyNodeDefinitions] = useState([])

  useEffect(() => {
    if (template && familyNodeDefinitions.length === 0) {
      const families = _.filter(template.node_definitions, item => item.type === nodeDefinitionTypeMap.family)
      setFamilyNodeDefinitions(_.values(families))
    }
  }, [template])

  const referenceProduct = useMemo(() => {
    return productsData.find(product => product.reference)
  }, [productsData])

  // filter families by model change data
  const familiesHasModelChange = useMemo(() => {
    const familyFiltered = _.filter(familyNodeDefinitions, nodeDef =>
      hasChildrenCriterionAnyModelChange(template, nodeDef, modelChangeData)
    )
    return familyFiltered
  }, [familyNodeDefinitions, modelChangeData, template])

  // open first tab row
  useEffect(() => {
    if (!expandedModelChangeRows && familiesHasModelChange && familiesHasModelChange?.length > 1) {
      const [firstFamilyFiltered] = familiesHasModelChange
      expandModelChangeRow(firstFamilyFiltered.id)
    }
  }, [familiesHasModelChange])

  return (
    <>
      {familiesHasModelChange.map(item => (
        <Row
          referenceProduct={referenceProduct}
          familyNodeDefinitions={familyNodeDefinitions}
          nodeDefinition={item}
          key={`row-${item.id}`}
        />
      ))}
      <tr className="comparison-board-table__body-row comparison-board-table__end-row">
        <td className="comparison-board-table__header-cell comparison-board-table__header-cell--model-change" />
      </tr>
      {!referenceProduct && (
        <tr className="comparison-board-table__notice">
          <td>{texts.please_select_reference_vehicle}</td>
        </tr>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    template: selectComparisonBoardProductTemplate(state),
    productsData: selectComparisonBoardProducts(state),
    modelChangeData: state.renaultProjectMode.comparisonBoard.modelChangeData,
    expandedModelChangeRows: state.renaultProjectMode.comparisonBoard.expandedModelChangeRows,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ModelChange)
