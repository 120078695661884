import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import '../../../../styles/node-modal.scss'
import 'swiper/swiper.min.css'
import { selectComparisonBoardProducts } from '../../../../state/comparisonBoard/selectors'
import HeaderCell from './HeaderCell'

function HeadersRow({ productsData, nodeDefinition, nodes }) {
  const elements = useMemo(() => {
    const array = []

    _.each(productsData, product => {
      const node = _.find(nodes, _node => _node.product_id === product.product_id)
      array.push(<HeaderCell key={node.id} product={product} nodeDefinition={nodeDefinition} node={node} />)
    })

    return array
  }, [])

  return <div className="node-modal-headers-row">{elements}</div>
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    productsData: selectComparisonBoardProducts(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(HeadersRow)
