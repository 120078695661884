import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import {
  selectComparisonBoardOption,
  selectComparisonBoardProductTemplate,
} from '../../../state/comparisonBoard/selectors'
import BodyRow from './BodyRow'
import { RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE } from '../../../consts/comparisonBoard'
import ModelChange from './ModelChange'

function BodyRows({ template, modelChangeOption, selectedCriterionsIds, expandFirstCriterion }) {
  const [rootNodeDefinitions, setRootNodeDefinitions] = useState([])

  useEffect(() => {
    if (template && rootNodeDefinitions.length === 0) {
      const roots = _.filter(template.node_definitions, item => item.parent_id === null)
      setRootNodeDefinitions(_.values(roots))
    }
  }, [template])

  // VA-1137: Calculate filter criteria accordingly to the selected criterions types
  const [filteredIds, setFilteredIds] = useState({})

  const hasCriteryonTypeFiltered = nodeDefinition => {
    if (nodeDefinition.type === 'criterion') {
      return selectedCriterionsIds.includes(nodeDefinition?.criterion_template?.type?.id)
    }
    return false
  }

  const addNodeAndAncestorsIds = (list, nodeDefinitions, id, deep) => {
    if (deep > 0 && !list[id]) {
      list[id] = true
      if (nodeDefinitions[id].parent_id) {
        addNodeAndAncestorsIds(list, nodeDefinitions, nodeDefinitions[id].parent_id, deep - 1)
      }
    }
  }

  useEffect(() => {
    if (!selectedCriterionsIds || selectedCriterionsIds.length === 0) {
      setFilteredIds({})
      expandFirstCriterion()
    } else if (template) {
      const updatedFilteredIds = {}
      Object.values(template.node_definitions).forEach(nodeDefinition => {
        if (hasCriteryonTypeFiltered(nodeDefinition))
          addNodeAndAncestorsIds(updatedFilteredIds, template.node_definitions, nodeDefinition.id, 5)
      })
      expandFirstCriterion(Object.keys(updatedFilteredIds))
      setFilteredIds(updatedFilteredIds)
    }
  }, [template, selectedCriterionsIds])

  // VA-1137: End filter criteria

  return (
    <tbody className="comparison-board-table__body">
      {!modelChangeOption && (
        <>
          {rootNodeDefinitions.map(item => (
            <BodyRow nodeDefinition={item} key={`row-${item.id}`} filteredIds={filteredIds} />
          ))}
          <tr className="comparison-board-table__body-row comparison-board-table__end-row" />
        </>
      )}

      {modelChangeOption && <ModelChange />}
    </tbody>
  )
}

const mapStateToProps = state => {
  return {
    template: selectComparisonBoardProductTemplate(state),
    modelChangeOption: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE),
    texts: state.texts.values,
    selectedCriterionsIds: state.renaultProjectMode.comparisonBoard.selectedCriterionsIds,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(BodyRows)
