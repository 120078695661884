import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'

function ProductPrice({ environment, product }) {
  const productPrice = product?.props?.find(prop => prop.slug === 'price')
  const productPriceValue = productPrice?.props?.find(prop => prop.slug === 'price-of-model')?.value?.body?.[
    environment.lang
  ]
  const productPriceCurrency = productPrice?.props?.find(prop => prop.slug === 'price-currency')?.value?.body?.[
    environment.lang
  ]

  const productPriceMaximum = productPrice?.props?.find(prop => prop.slug === 'price-maximum')?.value?.body?.[
    environment.lang
  ]
  const productPriceMinimum = productPrice?.props?.find(prop => prop.slug === 'price-minimum')?.value?.body?.[
    environment.lang
  ]

  const formatPrice = price => {
    return price?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }

  return (
    <>
      {productPriceValue && productPriceCurrency && (
        <div className="product-card__price">
          {formatPrice(productPriceValue)} {productPriceCurrency}
        </div>
      )}
      {productPriceMinimum && productPriceMaximum && productPriceCurrency && !productPriceValue && (
        <div className="product-card__price">
          {formatPrice(productPriceMinimum)} - {formatPrice(productPriceMaximum)} {productPriceCurrency}
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProductPrice)
