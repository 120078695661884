import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import '../../../../styles/node-modal.scss'
import 'swiper/swiper.min.css'
import { selectComparisonBoardProducts } from '../../../../state/comparisonBoard/selectors'
import CommentsCell from './CommentsCell'

function CommentsRow({ productsData, nodes }) {
  const elements = useMemo(() => {
    const array = []

    _.each(productsData, product => {
      const node = nodes[product.product_id]
      array.push(<CommentsCell product={product} node={node} key={node.id} />)
    })

    return array
  }, [])

  return <>{elements}</>
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    productsData: selectComparisonBoardProducts(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(CommentsRow)
