import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'

const uuidv4 = require('uuid/v4')

function ScorePanel({ scorePanel }) {
  const shortenedName = name => {
    switch (name.toLowerCase()) {
      case 'all':
        return 'All'
      case 'exterior':
        return 'Ext'
      case 'interior':
        return 'Int'
      case 'digital':
        return 'Digit'
      case 'cargo interior':
        return 'Cargo'
      default:
        return ''
    }
  }

  const selectInitValue = values =>
    _.find(values, _value => _value.name.en && _value.name.en.toLowerCase() === 'initial impression')

  const selectDetailValue = values =>
    _.find(values, _value => _value.name.en && _value.name.en.toLowerCase() === 'detailed analysis')

  const roundedValue = value => Math.round(value)

  const hasMultipleValues = Object.keys(scorePanel?.[0])?.some(key => key === 'values')

  return (
    <div className="comparison-board-product-card__score-panel" style={{ paddingRight: !hasMultipleValues && 15 }}>
      <table>
        <thead>
          <tr>
            <th />
            {hasMultipleValues && (
              <>
                <th className="head-cell">Init.</th>
                <th className="head-cell">Detail.</th>
              </>
            )}
          </tr>
        </thead>

        <tbody>
          {scorePanel &&
            scorePanel.map(item => {
              const initValue = selectInitValue(item.values)
              const detailValue = selectDetailValue(item.values)

              return (
                <tr key={uuidv4()}>
                  {hasMultipleValues ? (
                    <>
                      <td className="head-cell">{shortenedName(item.name)}</td>
                      {initValue && (
                        <td className="value-cell">
                          {initValue.is_enabled && initValue.value && roundedValue(initValue.value)}
                        </td>
                      )}
                      {detailValue && (
                        <td className="value-cell">
                          {detailValue.is_enabled && detailValue.value && roundedValue(detailValue.value)}
                        </td>
                      )}
                    </>
                  ) : (
                    <>
                      <td className="head-cell" style={{ width: 60 }}>
                        {shortenedName(item.name)}
                      </td>
                      {item.value && (
                        <td className="value-cell" style={{ width: 'auto', marginRight: 20 }}>
                          {roundedValue(item.value)}
                        </td>
                      )}
                    </>
                  )}
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ScorePanel)
