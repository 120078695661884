import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Switcher from '../../Common/Switcher'
import { RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE } from '../../../../consts/comparisonBoard'
import { selectComparisonBoardOption, selectComparisonBoardProducts } from '../../../../state/comparisonBoard/selectors'

function ModelChangeToggle({ value, texts, products, toggleComparisonBoardOption }) {
  const handleChange = () => {
    toggleComparisonBoardOption(RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE)
  }

  return (
    <div className="comparison-board-switcher__wrapper">
      <div className="comparison-board-switcher__label">{texts.model_change}</div>
      <Switcher onChange={handleChange} value={value} size="small" disabled={products.length === 0} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    value: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE),
    products: selectComparisonBoardProducts(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ModelChangeToggle)
