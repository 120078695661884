import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Button from '../../Common/Button'
import Config from '../../../../config.json'

function ValidateButton({ selectedIds, onConfirm, texts }) {
  const maxProducts = Config.configuration_category_max_products

  return (
    <Button
      className="products-search-modal__validate"
      label={`${texts.validate} (${selectedIds.length}/${maxProducts})`}
      color="primary"
      size="large"
      onClick={() => {
        onConfirm(selectedIds)
      }}
      disabled={selectedIds.length > maxProducts}
    />
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    selectedIds: state.renaultProjectMode.searchProducts.selectedIds,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ValidateButton)
