import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import DragAndDropIcon from '../../../../../../../assets/svg/drag-icon-small-white.svg'
import { selectComparisonBoardReferenceProduct } from '../../../../../state/comparisonBoard/selectors'

function DragHandle({ referenceProduct }) {
  return (
    <div
      className={classNames(
        'comparison-board-product-card__button',
        'comparison-board-product-card__button--drag-handle',
        {
          'comparison-board-product-card__button--disabled': referenceProduct,
        }
      )}
    >
      <DragAndDropIcon width="14" />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    referenceProduct: selectComparisonBoardReferenceProduct(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(DragHandle)
