import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import '../../../../styles/node-modal.scss'
import 'swiper/swiper.min.css'
import { selectComparisonBoardProducts } from '../../../../state/comparisonBoard/selectors'
import { productName } from '../../../../services/product'
import ScoreContributionValue from '../ScoreContributionValue'
import PercentageValue from '../PercentageValue'
import WeightValue from '../WeightValue'

function HeaderCell({ product, node, nodeDefinition, environment }) {
  const { is_enabled: isEnabled } = node
  const [name, setName] = useState('')

  useEffect(() => {
    setName(productName(product, environment.defaultLang))
  }, [product])

  return (
    <div className="node-modal-header-cell">
      <div className="node-modal-header-cell__product-name">{name}</div>

      <div className="node-modal-header-cell__info-wrapper">
        {isEnabled && (
          <>
            <ScoreContributionValue
              className="node-modal-header-cell__score-contribution"
              nodeDefinition={nodeDefinition}
              node={node}
            />
            <PercentageValue className="node-modal-header-cell__score" node={node} />
            <WeightValue className="node-modal-header-cell__weight" nodeDefinition={nodeDefinition} node={node} />
          </>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    productsData: selectComparisonBoardProducts(state),
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(HeaderCell)
