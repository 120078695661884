import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import InstructionIcon from '../../../../../../assets/svg/instruction-icon.svg'
import Guidelines from '../../../../../../components/scoring_tree/_parts/rightPanel/guidelines'
import { selectComparisonBoardProductTemplate } from '../../../../state/comparisonBoard/selectors'

function IntructionModalToggler({ nodeDefinition, environment, template }) {
  const [openGuidelines, setOpenGuidelines] = useState(false)
  const hasInstructionImage = () => {
    if (nodeDefinition?.media !== undefined && nodeDefinition?.media?.length > 0) {
      const media = nodeDefinition.media.find(element => {
        return element.collection === `${environment.lang}/instruction`
      })

      if (media) {
        return true
      }
    }

    return false
  }

  useEffect(() => {
    if (openGuidelines) document.body.style.overflow = 'hidden'
  }, [openGuidelines])

  return (
    <>
      {hasInstructionImage() && (
        <div className="comparison-board-table__instruction-item">
          <div
            className="comparison-board-table__instruction-item-toggler"
            role="button"
            tabIndex={0}
            onClick={() => {
              setOpenGuidelines(true)
            }}
            onKeyPress={() => {}}
          >
            <InstructionIcon />
          </div>
          {openGuidelines && (
            <Guidelines
              nodeDefinitions={template?.node_definitions}
              nodeDefinition={nodeDefinition}
              updateShowGuidelines={(e, value) => {
                e.stopPropagation()
                setOpenGuidelines(value)
                if (!value) document.body.style.overflow = 'auto'
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    template: selectComparisonBoardProductTemplate(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(IntructionModalToggler)
