import _ from 'lodash'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import nodeDefinitionTypeMap from '../../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import NodeModalToggler from './ActionElements/NodeModalToggler'
import RowsExpander from './ActionElements/RowsExpander'
import ModelChangeItemToggler from './ActionElements/ModelChangeItemToggler'
import { selectIsExpandedComparisonBoardRow } from '../../../state/comparisonBoard/selectors'
import InstructionModalToggler from './ActionElements/InstructionModalToggler'

const classNames = require('classnames')

function HeaderCell({ nodeDefinition, environment, expanded, collapseRow, expandRow }) {
  const { type } = nodeDefinition
  const maxNameLength = type === nodeDefinitionTypeMap.item || type === nodeDefinitionTypeMap.criterion ? 80 : 21
  const name = useMemo(() => _.get(nodeDefinition, ['name', environment.defaultLang], ''), [nodeDefinition])
  const truncatedName = useMemo(
    () => (name.length > maxNameLength ? `${name.substring(0, maxNameLength).trim()}…` : name),
    [name, maxNameLength]
  )

  const handleClick = e => {
    if (type !== nodeDefinitionTypeMap.criterion) {
      e.preventDefault()
      if (expanded) {
        collapseRow(nodeDefinition.id)
      } else {
        expandRow(nodeDefinition.id)
      }
    }
  }

  return (
    <div
      className={classNames('comparison-board-table-header-cell__wrapper', {
        'comparison-board-table-header-cell__wrapper--expandible': type !== nodeDefinitionTypeMap.criterion,
      })}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex="0"
    >
      <div
        className={classNames('comparison-board-table-header-cell__name', {
          truncated: truncatedName.length < name.length,
        })}
        title={name}
      >
        {truncatedName}
      </div>

      <div className="comparison-board-table-header-cell__actions">
        {(type === nodeDefinitionTypeMap.item || type === nodeDefinitionTypeMap.criterion) && (
          <ModelChangeItemToggler nodeDefinition={nodeDefinition} />
        )}
        {type === nodeDefinitionTypeMap.criterion && <InstructionModalToggler nodeDefinition={nodeDefinition} />}
        {type === nodeDefinitionTypeMap.item && <NodeModalToggler nodeDefinitionId={nodeDefinition.id} />}
      </div>

      {type !== nodeDefinitionTypeMap.criterion && (
        <div className="comparison-board-table-header-cell__expander-wrapper">
          <RowsExpander expanded={expanded} />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    expanded: selectIsExpandedComparisonBoardRow(state, ownProps.nodeDefinition?.id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(HeaderCell)
