import React from 'react'
import { connect } from 'react-redux'
import { selectComparisonBoardOption, selectComparisonBoardProducts } from '../../../../state/comparisonBoard/selectors'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Switcher from '../../Common/Switcher'
import { RPM_COMPARISON_BOARD_OPTION_TO_REVIEW } from '../../../../consts/comparisonBoard'

function ToReview({ value, toggleComparisonBoardOption, products, texts }) {
  const handleChange = () => {
    toggleComparisonBoardOption(RPM_COMPARISON_BOARD_OPTION_TO_REVIEW)
  }

  return (
    <div className="comparison-board-switcher__wrapper">
      <div className="comparison-board-switcher__label">{texts.to_review}</div>
      <Switcher
        id="to-review-switcher"
        onChange={handleChange}
        value={value}
        size="small"
        disabled={products.length === 0}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    value: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_TO_REVIEW),
    products: selectComparisonBoardProducts(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ToReview)
