import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import CarIcon from '../../../../../../assets/svg/car.svg'
import ToggleButton from '../../Common/ToggleButton'
import nodeDefinitionTypeMap from '../../../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'

function ModelChangeItemToggler({
  nodeDefinition,
  toggleModelChangeItem,
  modelChangeItemSelectedId,
  modelChangeItems,
  modelChangeData,
}) {
  const [active, setActive] = useState(false)

  const isActive = () => {
    // if item is active, all its children are active
    if (
      nodeDefinition &&
      nodeDefinition.type === nodeDefinitionTypeMap.item &&
      nodeDefinition.children_ids?.length > 0
    ) {
      return nodeDefinition.children_ids.every(childId =>
        modelChangeData?.[modelChangeItemSelectedId]?.includes(childId)
      )
    }

    // criterion is active if it is in the modelChangeData
    if (nodeDefinition.type === nodeDefinitionTypeMap.criterion)
      return modelChangeData?.[modelChangeItemSelectedId]?.includes(nodeDefinition.id)

    return false
  }

  useEffect(() => {
    const nodeIsActive = isActive()
    setActive(nodeIsActive)
  }, [modelChangeData, modelChangeItemSelectedId])

  const onChange = () => {
    toggleModelChangeItem(nodeDefinition.id, modelChangeItemSelectedId)
  }

  return (
    <>
      {modelChangeItemSelectedId && (
        <div className="comparison-board-table__model-change-item-toggler">
          <ToggleButton onChange={onChange} icon={<CarIcon />} value={active} />
          <div className="comparison-board-table__model-change-name-popup">
            {modelChangeItems?.[modelChangeItemSelectedId]?.value}
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    modelChangeItemSelectedId: state.renaultProjectMode.comparisonBoard.modelChangeItemSelectedId,
    modelChangeData: state.renaultProjectMode.comparisonBoard.modelChangeData,
    modelChangeItems: state.renaultProjectMode.comparisonBoard.modelChangeItems,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ModelChangeItemToggler)
