import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import EyeIcon from '../../../../../../assets/svg/eye.svg'

function NodeModalToggler({ nodeDefinitionId, toggleNodeModal }) {
  return (
    <button
      type="button"
      className="comparison-board-table__node-modal-toggler"
      onClick={() => {
        toggleNodeModal(nodeDefinitionId)
      }}
    >
      <EyeIcon width="16" />
    </button>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(NodeModalToggler)
