import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import { selectProductById } from '../../../../state/products/selectors'

function ProductName({ product, environment }) {
  const brandName = product && _.find(product.props, { slug: 'brand' }).value.body[environment.defaultLang]
  const modelName = product && _.find(product.props, { slug: 'model' }).value.body[environment.defaultLang]
  const name = product && `${brandName} ${modelName}`

  return <>{name}</>
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    product: selectProductById(state, ownProps.productId),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProductName)
