import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'

function OrderByDropdown({ orderBy, updateSearchProductsParams, searchProducts, texts }) {
  const options = {
    az_asc: texts.sort_by_az,
    az_desc: texts.sort_by_za,
    created_at_desc: texts.sort_by_date_created_desc,
    created_at_asc: texts.sort_by_date_created_asc,
  }

  const handleChange = async e => {
    const newValue = e.target.value
    if (newValue !== orderBy) {
      await updateSearchProductsParams({
        orderBy: newValue,
      })
      await searchProducts()
    }
  }

  return (
    <div className="sort-by__wrapper">
      <span className="sort-by__label">{texts.sort_by}</span>
      <select className="sort-by" value={orderBy} onChange={handleChange}>
        {_.map(options, (label, index) => (
          <option key={index} value={index}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    orderBy: state.renaultProjectMode.searchProducts.params.orderBy,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(OrderByDropdown)
