import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { showPercentage } from '../../../../../../va-corejs-v3/utils'
import * as renaultProjectModeActionCreators from '../../../state/actions'

const classNames = require('classnames')

function PercentageValue({ className, node }) {
  const roundedValue = useMemo(
    () => (node.percentage ? `${Math.round(node.percentage)}%` : `${showPercentage(node.score, node.max_score)}`),
    [node]
  )

  return (
    <div className={classNames(className, 'comparison-board-table__percentage')}>
      <span className="comparison-board-table__percentage-value">{roundedValue}</span>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(PercentageValue)
