import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { calcCriterionCalculatedWeight } from '../../../services/product'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import {
  selectComparisonBoardProductById,
  selectComparisonBoardProductTemplate,
} from '../../../state/comparisonBoard/selectors'
import DeltaIcon from '../../../../../assets/svg/triangle.svg'

const classNames = require('classnames')

function DeltaContibutionValue({ className, nodeDefinition, template, product }) {
  /* const value = useMemo(() => {
    const nodeDefinition = _.find(template.node_definitions, _n => _n.id === node.node_definition_id)
    const fullValue = calcDeltaScoreContribution(node, nodeDefinition, product.nodes)
    return fullValue === Math.round(fullValue) ? fullValue : fullValue.toFixed(2)
  }, [node]) */

  const nodeDefinitions = template.node_definitions

  const value = useMemo(() => {
    let calculatedWeight
    let delta

    if (nodeDefinition?.type === 'criterion') {
      // Ticket VAV3-1164
      // Formula for delta
      // ((calculated weight / 100) * 500) / 4)

      calculatedWeight = calcCriterionCalculatedWeight(nodeDefinition, nodeDefinitions, product.nodes)
      if (calculatedWeight && nodeDefinition) delta = ((calculatedWeight / 100) * 500) / 4
    }

    return delta?.toFixed(2)
  }, [template])

  return (
    <div className={classNames(className, 'comparison-board-table__delta-score-contribution')}>
      <div className="comparison-board-table__delta-score-contribution-icon">
        <DeltaIcon width="12px" />
      </div>
      <div className="comparison-board-table__delta-score-contribution-value">{value}</div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    template: selectComparisonBoardProductTemplate(state),
    product: selectComparisonBoardProductById(state, ownProps.node.product_id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(DeltaContibutionValue)
