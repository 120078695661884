import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Switcher from '../../Common/Switcher'

function ConfigurationSwitcher({ includeConfigurations, updateSearchProductsParams, searchProducts, texts }) {
  const debouncedSearch = React.useRef(
    _.debounce(async updatedValue => {
      await updateSearchProductsParams({
        includeConfigurations: updatedValue,
      })

      await searchProducts()
    }, 300)
  ).current

  const handleChange = value => {
    debouncedSearch(value)
  }

  return (
    <div className="products-search-modal-switcher__wrapper">
      <div className="products-search-modal-switcher__label">{texts.abbr_configuration}</div>
      <Switcher id="configuration-switcher" onChange={handleChange} value={includeConfigurations} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    includeConfigurations: state.renaultProjectMode.searchProducts.params.includeConfigurations,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ConfigurationSwitcher)
