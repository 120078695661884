import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import '../../../../styles/node-modal.scss'
import 'swiper/swiper.min.css'
import { selectNodeMedia } from '../../../../state/products/selectors'
import { calcNodeScore } from '../../../../services/product'
import {
  selectComparisonBoardProductById,
  selectComparisonBoardProductTemplate,
} from '../../../../state/comparisonBoard/selectors'
import CommentsOutlineIcon from '../../../../../../assets/svg/comments-outline.svg'
import MediaOutlineIcon from '../../../../../../assets/svg/media-outline.svg'
import nodeDefinitionTypeMap from '../../../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'

function CriterionCell({ nodeDefinition, node, media, template, product }) {
  const { type, bonus_demerit: bonusDemerit } = nodeDefinition
  const { is_default: isDefault, is_enabled: isEnabled } = node
  const score = useMemo(() => calcNodeScore(node, nodeDefinition, template, product), [node])

  const classSuffix = useMemo(() => {
    if (type === nodeDefinitionTypeMap.criterion) {
      return score < 0 ? `n${Math.abs(score)}` : score
    }

    return ''
  }, [type, score])

  if (!isEnabled) {
    return <div className="node-modal-criterion-cell" />
  }

  return (
    <div className="node-modal-criterion-cell">
      <div className="node-modal-criterion-cell__body">
        <div
          className={classNames(
            'node-modal-criterion__score',
            'product-score__stamp',
            `product-score__stamp--score-${classSuffix}`,
            {
              'product-score__stamp--bonus-demerit': bonusDemerit,
              'product-score__stamp--default': isDefault,
            }
          )}
        >
          {score}
        </div>

        <div className="node-modal-criterion__counters">
          {node.comments && (
            <div className="node-modal-criterion-counter node-modal-criterion-counter--comments">
              <div className="node-modal-criterion-counter__icon">
                <CommentsOutlineIcon width="11" />
              </div>
              <span className="node-modal-criterion-counter__value">{node.comments.length}</span>
            </div>
          )}

          {media && (
            <div className="node-modal-criterion-counter node-modal-criterion-counter--media">
              <div className="node-modal-criterion-counter__icon">
                <MediaOutlineIcon width="15" />
              </div>
              <span className="node-modal-criterion-counter__value">{media.length}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    media: selectNodeMedia(state, ownProps.node.product_id, ownProps.node.id),
    template: selectComparisonBoardProductTemplate(state),
    product: selectComparisonBoardProductById(state, ownProps.node.product_id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(CriterionCell)
