import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import '../../../../styles/node-modal.scss'
import 'swiper/swiper.min.css'
import { selectComparisonBoardProducts } from '../../../../state/comparisonBoard/selectors'
import CriterionCell from './CriterionCell'

function CriteriaRow({ productsData, nodeDefinition, nodes, environment }) {
  const name = useMemo(() => _.get(nodeDefinition, ['name', environment.defaultLang], ''), [nodeDefinition])

  const elements = useMemo(() => {
    const array = []

    _.each(productsData, product => {
      const node = _.find(nodes, _node => _node.product_id === product.product_id)
      array.push(<CriterionCell key={node.id} nodeDefinition={nodeDefinition} node={node} />)
    })

    return array
  }, [])

  return (
    <div className="node-modal-criteria-row">
      <div className="node-modal-criteria-row__name">{name}</div>
      <div className="node-modal-criteria-row__list">{elements}</div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    productsData: selectComparisonBoardProducts(state),
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(CriteriaRow)
