import React, { useState } from 'react'
import { connect } from 'react-redux'
import { selectComparisonBoardOption, selectComparisonBoardProducts } from '../../../../state/comparisonBoard/selectors'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Switcher from '../../Common/Switcher'
import { RPM_COMPARISON_BOARD_OPTION_FULL_DATA } from '../../../../consts/comparisonBoard'
import Dialog from '../../Common/Dialog'
import Button from '../../Common/Button'
import QuestionIcon from '../../../../../../assets/svg/question_bubble.svg'

function FullData({ value, products, toggleComparisonBoardOption, texts, environment }) {
  const [openTutorialDialog, setOpenTutorialDialog] = useState(false)

  const handleChange = () => {
    toggleComparisonBoardOption(RPM_COMPARISON_BOARD_OPTION_FULL_DATA)
  }

  return (
    <div className="comparison-board-switcher__wrapper">
      <div className="comparison-board-switcher__label">
        <Button
          className="comparison-board-switcher__tutorial-button"
          onClick={() => setOpenTutorialDialog(true)}
          label={texts.full_data}
          formatted={false}
          iconAfter={<QuestionIcon />}
        />
      </div>
      <Switcher
        id="full-data-switcher"
        onChange={handleChange}
        value={value}
        size="small"
        disabled={products.length === 0}
      />
      <Dialog
        open={openTutorialDialog}
        setOpen={setOpenTutorialDialog}
        title={texts.how_to_read_full_data_board}
        confirmButtonText={texts.got_it}
        cancelButton={false}
        width={639}
      >
        <div className="comparison-board-switcher__tutorial-image">
          <img
            src={`/renault-project-mode/tutorial_${environment?.lang ?? environment?.defaultLang}.svg`}
            alt="tutorial"
          />
        </div>
      </Dialog>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    value: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_FULL_DATA),
    products: selectComparisonBoardProducts(state),
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(FullData)
