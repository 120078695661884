import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import { selectSearchedProducts } from '../../../../state/searchProducts/selectors'
import Item from './Item'
import Pagination from './Pagination'

function Body({ products }) {
  return (
    <div className="products-search-modal__body">
      {products.map(product => (
        <Item key={_.uniqueId()} product={product} />
      ))}
      <Pagination />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    products: selectSearchedProducts(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Body)
