import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import BlobUrlImage from '../../Common/BlobUrlImage'
import ScorePanel from '../../../../../../components/commons/score_panel'
import AddIcon from '../../../../../../assets/svg/compar-add.svg'
import RemoveIcon from '../../../../../../assets/svg/compar-remove.svg'

const classNames = require('classnames')

function Item({ product, selectedIds, environment, searchProductsSelectProduct, searchProductsDeselectProduct }) {
  const [productName, setProductName] = useState('')
  const selected = _.includes(selectedIds, product.id)

  useEffect(() => {
    const brand = _.get(product, 'props.brand.value')
    const model = _.get(product, 'props.model.value')
    setProductName(`${brand} ${model}`)
  }, [])

  const handleClick = () => {
    if (selected) {
      searchProductsDeselectProduct(product)
    } else {
      searchProductsSelectProduct(product)
    }
  }

  return (
    <div
      className={classNames({ 'product-search-item': true, 'product-search-item--selected': selected })}
      onClick={handleClick}
      onKeyPress={handleClick}
      role="button"
      tabIndex={0}
    >
      <div className="product-search-item__cover">
        <BlobUrlImage url={_.get(product, 'cover')} />
      </div>
      <div className="product-search-item__brand-image">
        <BlobUrlImage url={_.get(product, 'props.brand.media')} />
      </div>
      <div className="product-search-item__title">
        <div className="product-search-item__name">{productName}</div>
        <div className="product-search-item__subname">{_.get(product, 'props.commercial-name-grade.value')}</div>
      </div>
      <div className="product-search-item__score-panel">
        <ScorePanel
          scorePanel={product.score_panel}
          size="mashed"
          maxProductScore={environment.config.max_product_score}
        />
      </div>
      <div className="product-search-item__actions">
        {selected ? <RemoveIcon className="theme_circle" /> : <AddIcon className="theme_svg" />}
      </div>
    </div>
  )
}

Item.defaultProps = {
  defaultSelected: false,
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    selectedIds: state.renaultProjectMode.searchProducts.selectedIds,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Item)
